<template>
  <v-card class="voucher-wrapper">
    <v-card-title>
      <div class="title">
        Create Voucher
      </div>
    </v-card-title>
    <v-card-text class="picker-wrap">
      <div class="voucher-form">
        <div>Name</div>
        <div>
          <v-text-field
            v-model="form.name"
            outlined
            placeholder="Voucher's name"
          ></v-text-field>
        </div>
        <div>Type</div>

        <v-radio-group v-model="type" column>
          <v-radio
            label="Percentage discount"
            color="primary"
            value="percent_off"
          ></v-radio>
          <v-radio
            label="Fixed amount discount"
            color="primary"
            value="amount_off"
          ></v-radio>
        </v-radio-group>
        <div>Amount</div>
        <div>
          <v-text-field
            v-model.number="amount"
            outlined
            number
            class="w-50"
            placeholder="Amount"
          ></v-text-field>
        </div>
        <div>Duration</div>
        <v-radio-group v-model="form.duration" column>
          <v-radio
            v-for="duration in durations"
            :key="duration.value"
            :label="duration.name"
            color="primary"
            :value="duration.value"
          ></v-radio>
        </v-radio-group>
        <div>
          <v-text-field
            v-if="form.duration === 'repeating'"
            v-model.number="form.duration_in_months"
            outlined
            number
            class="w-50"
            placeholder="Duration in months"
          ></v-text-field>
        </div>
        <div>Redemption limits</div>
        <v-checkbox
          v-for="limit in redemptionLimitsItems"
          multiple
          v-model="redemptionLimits"
          :key="limit.value"
          :value="limit.value"
          :label="limit.text"
        />
        <date-picker
          v-if="
            redemptionLimits && redemptionLimits.includes('expiration_date')
          "
          v-model="redeemBy"
          :minDate="setTomorrowDate"
          label="Redeem by"
          cancel-button
          reset-button
        ></date-picker>

        <v-text-field
          v-if="
            redemptionLimits && redemptionLimits.includes('max_redemptions')
          "
          number
          v-model.number="form.max_redemptions"
          outlined
          placeholder="Limit"
          class="w-50"
        ></v-text-field>
      </div>
    </v-card-text>
    <v-card-actions v-if="actionButtons">
      <v-spacer></v-spacer>
      <v-btn text @click="cancel">
        cancel
      </v-btn>
      <v-btn
        color="primary"
        :loading="loading"
        @click="createVoucher('update')"
      >
        Create voucher
      </v-btn>
    </v-card-actions>
    <v-card-actions v-else>
      <div class="d-flex action-btn mt-15 mb-4 w-100">
        <v-btn color="primary" text @click="$emit('cancel')" outlined
          >PREVIOUS</v-btn
        >
        <v-btn
          color="primary"
          medium
          :loading="loading"
          @click="createVoucher('create')"
          >NEXT</v-btn
        >
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import friendly_numbers from "@/helpers/filters/friendly_numbers";
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import DatePicker from "@/components/common/filters/DatePicker";
import { mapActions } from "vuex";

export default {
  components: { DatePicker },
  filters: { friendly_numbers, local_numbers, utc_to_local_datetime },
  props: {
    subscription: {
      default: () => {}, // Default object
    },
    value: {
      required: false,
      type: Boolean,
    },
    actionButtons: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    amount: null,
    durations: [
      {
        name: "Once",
        value: "once",
      },
      {
        name: "Forever",
        value: "forever",
      },
      {
        name: "Multiple months",
        value: "repeating",
      },
    ],
    redemptionLimitsItems: [
      {
        text: "Add an expiration date",
        value: "expiration_date",
      },
      {
        text: "Limit  the total number of times this voucher can be redeemed",
        value: "max_redemptions",
      },
    ],
    redemptionLimits: null,
    redeemBy: null,
    loading: false,
    form: {
      duration: "once",
    },
    type: "percent_off",
    confirmAddingDays: false,
  }),
  computed: {
    showing: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    setTomorrowDate() {
      const tomorrowMoment = this.$moment().clone().add(1,'days').format("YYYY-MM-DD")
      return tomorrowMoment
    }
  },
  methods: {
    ...mapActions("moderator", ["createDiscount"]),
    ...mapActions("core", ["updateSubscription"]),
    cancel() {
      this.$emit("cancel", true);
      this.form = {
        duration: "once",
      };
    },
    createVoucher(value) {
      this.loading = true;
      let params = {
        ...this.form,
        [this.type]: this.amount,
      };
      if (this.redeemBy) {
        params.redeem_by = this.$moment(this.redeemBy).format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }
      this.createDiscount(params).then((voucher) => {
        if (voucher && value === "update") {
          this.updateSubscription({
            id: this.subscription.stripe_id,
            coupon: voucher.id,
          }).then(() => {
            this.$emit("success");
            this.setSnackSuccess("Gemt");
            this.loading = false;
            this.showing = false;
            this.form = {
              duration: "once",
            };
          });
        } else {
          this.$emit("success", voucher);
        }
      }),
        () => {
          this.loading = false;
          this.setSnackError("Fejl");
        };
    },
  },
};
</script>
<style lang="scss">
.action-btn {
  justify-content: space-between;
}
.free-days-wrapper {
  font-size: 14px;
  color: #000000;
  .headline {
    font-size: 16px !important;
    font-weight: 500;
  }
  .subtitle {
    font-size: 14px !important;
  }
  .trial-days-wrap {
    font-size: 14px;
    font-weight: 300;
  }
  .picker-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.confirmation-wrapper {
  .v-card__title,
  .v-card__text {
    justify-content: center;
    text-align: center;
  }
}
.voucher-wrapper {
  box-shadow: none !important;
}
</style>
