<template>
  <v-dialog
    v-model="showing"
    max-width="500px"
    content-class="cancel-subscription-wrapper "
  >
    <v-card v-if="!approvedCancel">
      <v-card-title>
        <div>
          <div class="headline">
            Cancel this subscription?
          </div>
          <div class="headline-description">
            Are you sure you want to
            <span class="error--text">cancel</span> this subscription?
          </div>
          <div class="subscription-info">
            {{ subscription.product }}, {{ subscription.stripe_plan_name }}
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-radio-group
          v-model="form.cancel_reason"
          column
          label="Cancellation reasons"
        >
          <v-radio
            v-for="reason in cancelReasons"
            :key="reason.value"
            :label="reason.text"
            :value="reason.value"
          ></v-radio>
        </v-radio-group>
        <v-textarea
          outlined
          label="Comment"
          v-model="form.cancel_reason_text"
          :counter="500"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('cancel', true)">
          cancel
        </v-btn>
        <v-btn
          color="error"
          text
          outlined
          :disabled="
            (form.cancel_reason === 'other' && !form.cancel_reason_text || 
             form.cancel_reason === 'other' &&  form.cancel_reason_text.length < 3) ||
              !form.cancel_reason
              
          "
          :loading="loading"
          @click="approvedCancel = true"
        >
          Yes, cancel
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <v-row class="cancel-wrap">
          <v-col cols="6">
            <v-subheader>Cancel</v-subheader>
          </v-col>
          <v-col cols="12">
             <v-radio-group v-model="form.cancel_at_period_end" column>
           
            <div class="d-flex">
               <v-radio
              label="Immediately"
              color="primary"
              :value="false"
            ></v-radio>
               <span class="grey-text ">{{new Date() | utc_to_local_datetime('D MMMM YYYY')}}</span>
            </div>
            <div class="d-flex">
            <v-radio
              label="End of the current period"
              color="primary"
              :value="true"
            ></v-radio>
         
               <span class="grey-text ">{{ subscription.next_period_at | utc_to_local_datetime('D MMMM YYYY')}}</span>
            </div>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('cancel', true)">
          cancel
        </v-btn>
        <v-btn
          color="primary"
          :loading="loading"
          @click="stopSubscription()"
        >
          confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import friendly_numbers from "@/helpers/filters/friendly_numbers";
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";

import { mapActions } from "vuex";

export default {
  filters: { friendly_numbers, local_numbers, utc_to_local_datetime },
  props: {
    subscription: {
      required: true,
      default: () => {}, // Default object
    },
    value: {
      required: true,
    },
  },
  data: () => ({
    confirmed: null,
    form: {
      cancel_at_period_end: true
    },
    cancelReasons: [
      { value: "lacking_time", text: "Har ikke tiden til det" },
      {
        value: "pausing_influencer_marketing",
        text: "Holder pause med influencer marketing",
      },
      {
        value: "influencers_not_active",
        text: "Influencerne er ikke aktive nok",
      },
      {
        value: "trouble_finding_influencers",
        text: "For besværligt at finde de rigtige influencere",
      },
      {
        value: "our_product_doesnt_fit",
        text: "Vores produkt passer ikke til Jeres influencere",
      },
      {
        value: "platform_hard_to_understand",
        text: "Platformen er svær at forstå",
      },
      {
        value: "expected_more_service",
        text: "Forventede mere hjælp og service",
      },
      {
        value: "too_many_products",
        text: "Skulle sende for mange produkter til influencerne",
      },
      {
        value: "not_enough_conversions",
        text: "Ikke nok konverteringer/salg",
      },
      {
        value: "other",
        text: "Other",
      },
    ],
    loading: false,
    approvedCancel: false
  }),
  computed: {
    showing: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions("core", ["cancelSubscription"]),
    stopSubscription() {
      this.loading = true;
        const params = {
          ...this.form,
          business_uuid: this.subscription.user.business.uuid
        };
        this.cancelSubscription(params).then(
        (response) => {
          if (response.error_code) {
            this.setSnackError("Noget gik galt");
            this.loading = false;
          } else {
            this.cancelDialog = false;
            this.loading = false;
            this.cancelReason = null;
            this.cancelReasonText = "";
            this.$emit("success");
            this.setSnackSuccess("Abonnementet er opsagt");
          }
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.setSnackError("Noget gik galt");
        }
      );
    },
    setPeriod(date) {
      this.$options.filters.utc_to_local_datetime(date, 'D MMMM YYYY');
    }
  },
};
</script>
<style lang="scss">
.cancel-subscription-wrapper {
  font-size: 14px;
  color: var(--v-primary-base);

  .headline {
    font-size: 16px;
    font-weight: 500;
  }
  .headline-description,
  .v-label {
    font-size: 14px;
    font-weight: 400;
    color: var(--v-primary-base);
  }
  legend {
    margin-bottom: 20px;
  }
  .subscription-info {
    background: rgba(240, 32, 82, 0.1);
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
    color: var(--v-primary-base);
    padding: 10px;
  }
}
.cancel-wrap,
.cancel-wrap div,
.v-subheader{
  font-size: 16px;
  color: var(--v-primary-base)!important;
  font-weight: 500;
  padding: 0;
}
.grey-text {
  font-size: 14px;
  color: var(--v-greytxt-base)!important;
  font-weight: 400;
  margin-left: 5px;
  margin-bottom: 8px;
}
</style>
