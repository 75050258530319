<template>
  <div class="subscription-wrap" v-if="subscription">
    <div class="subscription-header">
      <div class="subscription-title">
        Subscription page
      </div>
      <v-menu offset-y>
        <template
          v-slot:activator="{ on, attrs }"
          v-if="
            allowedResumeSubscription ||
              subscription.stripe_status !== 'canceled'
          "
        >
          <v-btn color="primary" v-bind="attrs" v-on="on">
            <v-icon color="white" class="mr-2 icon-size">fal fa-cogs</v-icon>
            Action
          </v-btn>
        </template>
        <v-list v-if="allowedResumeSubscription">
          <v-list-item>
            <v-list-item-title
              class="action-btn"
              @click="resumeSubscriptionDialog = true"
              >Resume subscription</v-list-item-title
            >
          </v-list-item>
        </v-list>
        <v-list v-else-if="subscription.stripe_status !== 'canceled'">
          <v-list-item v-for="(item, index) in actions" :key="index">
            <v-list-item-title
              class="action-btn"
              :class="item.color"
              @click="editSubscriptionButtons(item.title)"
              >{{ item.title }}</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-system-bar
      v-if="isSubscriptionScheduled"
      color="#FED744"
      :height="45"
      class="mt-6 mx-3"
    >
      <div class="text-center white--text">
        <v-icon color="white" small>fal fa-exclamation-triangle</v-icon>
        There is a schedule change in the future
      </div>
    </v-system-bar>
    <div class="subscription-content">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="4">
            <div class="subscription-info subscription-border mr-2 h-100 ">
              <div class="business-name">
                {{ subscription.user.business.display_name }}
              </div>
              <div class="mb-2">
                {{ subscription.user.first_name }}
                {{ subscription.user.last_name }}
              </div>
              <div class="mb-2 grey-text">
                {{ subscription.user.email }}
              </div>
              <div class="mb-2">
                <router-link
                  :to="{
                    name: 'business',
                    params: { id: subscription.user.business.uuid },
                  }"
                >
                  Link to business profile
                </router-link>
              </div>
              <div v-if="subscription.trial_ends_at">
                <div class="table-row-title">Trial:</div>
                <div class="table-row-content">
                  {{
                    subscription.trial_ends_at
                      | utc_to_local_datetime("DD-MM-YYYY")
                  }}
                </div>
              </div>

              <div class="table-row-title mt-2">Tax:</div>
              <div class="d-flex align-center">
                <div
                  class="table-row-content"
                  v-if="subscription.default_tax_rates"
                >
                  <span>
                    {{ displayTaxes }}
                  </span>
                </div>
                <div class="table-row-content" v-else>
                  No tax added
                </div>
                <v-menu
                  :close-on-content-click="false"
                  :nudge-width="200"
                  v-model="menuTax"
                  offset-x
                >
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" text color="grey" class="text-lowercase">
                      <v-icon small class="icon-size">fal fa-pen</v-icon>
                      edit
                    </v-btn>
                  </template>

                  <v-card flat class="p-0">
                    <v-card-text>
                      <div class="greytxt--text">
                        Change tax
                      </div>
                      <v-select
                        v-model="tax"
                        :items="taxRates"
                        item-text="display_name"
                        item-value="stripe_tax_rate_id"
                        clearable
                        multiple
                        class="mr-10 filter-select width"
                      >
                      </v-select>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="close" class="primary--text"
                          >Cancel</v-btn
                        >
                        <v-btn color="primary" :loading="loading" @click="save"
                          >Save</v-btn
                        >
                      </v-card-actions>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>

              <div class="mt-2">
                <div class="table-row-title">Collection method:</div>
                <div class="d-flex align-center">
                  <div
                    class="table-row-content"
                    v-if="subscription.collection_method"
                  >
                    {{ setCollectionMethod(subscription.collection_method) }}
                  </div>
                  <div class="table-row-content" v-else>
                    No collection method added
                  </div>
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-width="200"
                    v-model="menuCollectionMethod"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" color="grey" class="text-lowercase" text>
                        <v-icon small class="icon-size">fal fa-pen</v-icon>
                        edit
                      </v-btn>
                    </template>

                    <v-card flat class="p-0">
                      <v-card-text>
                        <v-radio-group v-model="collectionMethod" column>
                          <v-radio
                            v-for="method in collectionMethodList"
                            :key="method.label"
                            :label="method.label"
                            color="primary"
                            :value="method.value"
                          ></v-radio>
                        </v-radio-group>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn text @click="close" class="primary--text"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="primary"
                            :loading="loading"
                            @click="save"
                            >Save</v-btn
                          >
                        </v-card-actions>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
              </div>
              <div class="mt-2" v-if="allowedChangeDaysUntilDue">
                <div class="table-row-title">Days until invoice due</div>
                <div class="d-flex align-center">
                  <div class="table-row-content">
                    {{
                      subscription.days_until_due
                        ? subscription.days_until_due
                        : "You can add days until due"
                    }}
                  </div>

                  <v-menu
                    :close-on-content-click="false"
                    :nudge-width="200"
                    v-model="menuDaysUntilInvoiceDue"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" color="grey" class="text-lowercase" text>
                        <v-icon small class="icon-size">fal fa-pen</v-icon>
                        edit
                      </v-btn>
                    </template>

                    <v-card flat class="p-0">
                      <v-card-text>
                        <v-text-field
                          label="Days until invoice due"
                          v-model="daysUntilInvoiceDue"
                          number
                        ></v-text-field>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn text @click="close" class="primary--text"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="primary"
                            :disabled="!daysUntilInvoiceDue"
                            :loading="loading"
                            @click="save"
                            >Save</v-btn
                          >
                        </v-card-actions>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
              </div>
              <div class="table-row-title">Created:</div>
              <div class="table-row-content">
                {{
                  subscription.created_at
                    | utc_to_local_datetime("DD-MM-YYYY HH:mm")
                }}
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div class="subscription-border mr-2 h-100">
              <div class="card-name">
                Product
              </div>
              <div class="business-name">
                <span v-if="subscription.product === 'standard'">
                  Self-service
                </span>
                <span v-else>
                  {{ subscription.product }}
                </span>
              </div>
              <div class="table-row-title mt-2">Interval:</div>
              <div class="table-row-content">
                {{ subscription.stripe_plan_name }}
              </div>
              <div class="table-row-title mt-2">Pricing:</div>
              <div class="table-row-content">
                DKK {{ subscription.monthly_recurring_revenue | price_format }}
              </div>

              <div class="table-row-title mt-2">Voucher:</div>
              <div class="d-flex align-center">
                <div class="table-row-content">
                  {{
                    subscription.stripe_discount
                      ? subscription.stripe_discount.stripe_coupon.name
                      : "No voucher added"
                  }}
                </div>
                <v-menu
                  :close-on-content-click="false"
                  :nudge-width="200"
                  offset-x
                >
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="grey" class="text-lowercase" text>
                      <v-icon small class="icon-size">fal fa-pen</v-icon>
                      edit
                    </v-btn>
                  </template>

                  <v-card flat class="p-0">
                    <v-card-text>
                      <v-list>
                        <v-list-item v-if="subscription.stripe_discount">
                          <v-list-item-title
                            class="action-btn"
                            @click="removeVoucherDialog = true"
                            >Delete voucher</v-list-item-title
                          >
                        </v-list-item>
                        <v-list-item v-if="!subscription.stripe_discount">
                          <v-list-item-title
                            class="action-btn"
                            @click="createVoucher = true"
                            >Add voucher</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
              <div
                v-if="
                  isSubscriptionScheduled && subscription.schedule.phases.length
                "
              >
                <div
                  v-for="phase in subscription.schedule.phases"
                  :key="phase.start_date"
                >
                  <div class="card-name mt-2">
                    Scedule change start date
                  </div>
                  <div class="table-row-title">{{ phase.start_date }}</div>
                  <div class="card-name mt-2">
                    New plan
                  </div>
                  <div class="table-row-title">
                    {{ phase.plans[0].nickname }}
                  </div>
                  <div class="table-row-title">
                    {{ phase.plans[0].amount }} {{ phase.plans[0].currency }} /
                    {{ phase.plans[0].interval_count }}
                    {{ phase.plans[0].interval }}
                  </div>
                </div>
              </div>
              <div
                class="remove-wrap mt-2"
                @click="removeScheduleDialog = true"
                v-if="isSubscriptionScheduled"
              >
                <v-icon small class="mr-2">fal fa-trash-alt</v-icon>
                DELETE SCHEDULE
              </div>
            </div>
          </v-col>

          <v-col cols="12" md="4">
            <div class="subscription-border">
              <div class="card-name">
                Status
              </div>
              <div
                class="mb-2 bigger-text"
                :class="statusColor(subscription.stripe_status)"
              >
                {{ subscription.stripe_status }}
              </div>
              <div>
                <div class="table-row-title mt-2">Cancellation date</div>
                <div class="table-row-content" v-if="subscription.canceled_at">
                  {{
                    subscription.canceled_at
                      | utc_to_local_datetime("MMM DD, YYYY")
                  }}
                </div>
                <div class="table-row-content" v-else>
                  -
                </div>
              </div>
              <div>
                <div class="table-row-title mt-2">Subscription ends</div>
                <div class="table-row-content" v-if="subscription.ends_at">
                  {{
                    subscription.ends_at | utc_to_local_datetime("MMM DD, YYYY")
                  }}
                </div>
                <div class="table-row-content" v-else>
                  -
                </div>
              </div>
              <div>
                <div class="table-row-title mt-2">Cancellation reason</div>
                <div
                  class="table-row-content"
                  v-if="subscription.cancel_reason_text"
                >
                  {{ subscription.cancel_reason_text }}
                </div>
                <div class="table-row-content" v-else>
                  -
                </div>
              </div>
            </div>
            <div class="subscription-border mt-7">
              <div class="card-name">
                MRR
              </div>
              <div class="mb-2 bigger-text">
                {{ subscription.monthly_recurring_revenue | price_format }} DKK
              </div>
              <div class="table-row-title mt-2">Next subscription period</div>
              <div class="table-row-content">
                {{
                  subscription.next_period_at
                    | utc_to_local_datetime("MMM DD, YYYY")
                }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="subscription-details">
      <div class="title">
        Invoices
      </div>
      <div class="subscription-details-table mt-15">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Amount
                </th>
                <th class="text-left">
                  Invoice NR.
                </th>
                <th class="text-left">
                  Due
                </th>
                <th class="text-left">
                  Created
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="invoice in invoices" :key="invoice.stripe_key">
                <td>{{ invoice.total }}</td>
                <td class="grey-text">{{ invoice.invoice_key }}</td>
                <td class="grey-text" v-if="invoice.paid">
                  {{ invoice.paid | utc_to_local_datetime("DD-MM-YYYY") }}
                </td>
                <td class="grey-text" v-else>&nbsp;</td>
                <td class="grey-text" v-if="invoice.date">
                  {{ invoice.date | utc_to_local_datetime("DD-MM-YYYY") }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <update-subscription
        v-if="editSubscription"
        v-model="editSubscription"
        :subscription="subscription"
        @cancel="editSubscription = false"
        @success="
          getSubscription();
          editSubscription = false;
        "
      />
      <cancel-subscription
        v-if="cancelSubscription"
        v-model="cancelSubscription"
        :subscription="subscription"
        @cancel="cancelSubscription = false"
        @success="
          cancelSubscription = false;
          getSubscription();
        "
      />
      <subscription-free-days
        v-model="addFreeDays"
        :subscription="subscription"
        @cancel="addFreeDays = false"
        :disabled="isSubscriptionScheduled"
        @success="
          getSubscription();
          addFreeDays = false;
        "
      >
      </subscription-free-days>
      <v-dialog
        v-model="createVoucher"
        max-width="450px"
        content-class="voucher-wrapper"
      >
        <create-voucher
          v-model="createVoucher"
          :actionButtons="true"
          :subscription="subscription"
          @cancel="createVoucher = false"
          @success="
            createVoucher = false;
            getSubscription();
          "
        >
        </create-voucher>
      </v-dialog>

      <v-dialog v-model="resumeSubscriptionDialog" max-width="500px">
        <v-card>
          <v-card-text class="pt-10 primary--text">
            Resuming a subscription will set it's status back to active, and
            they will be charged like normal
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="resumeSubscriptionDialog = false">
              cancel
            </v-btn>
            <v-btn
              color="primary"
              :loading="loading"
              @click="undoSubscription()"
            >
              confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="removeVoucherDialog" max-width="500px">
        <v-card>
          <v-card-text class="pt-10 primary--text">
            Are you sure you want to remove voucher ?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="removeVoucherDialog = false">
              cancel
            </v-btn>
            <v-btn color="primary" :loading="loading" @click="deleteVoucher()">
              confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="removeScheduleDialog" max-width="500px">
        <v-card>
          <v-card-text class="pt-10 primary--text">
            Are you sure you want to remove schedule ?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="removeScheduleDialog = false">
              cancel
            </v-btn>
            <v-btn color="primary" :loading="loading" @click="removeSchedule()">
              confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import price_format from "@/helpers/filters/price_format.js";
import search_filters_url from "@/helpers/mixins/search_filters_url";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import UpdateSubscription from "../dialogs/UpdateSubscription";
import CancelSubscription from "../dialogs/CancelSubscription";
import SubscriptionFreeDays from "../dialogs/SubscriptionFreeDays";
import CreateVoucher from "../dialogs/CreateVoucher";

import { mapActions } from "vuex";

export default {
  mixins: [search_filters_url],
  filters: { price_format, utc_to_local_datetime },
  components: {
    UpdateSubscription,
    CancelSubscription,
    SubscriptionFreeDays,
    CreateVoucher,
  },
  data: () => ({
    loading: false,
    collectionMethod: "charge_automatically",
    collectionMethodList: [
      {
        label: "Automatically charge a payment  from the card",
        value: "charge_automatically",
      },
      {
        label: "Email invoice to the customer to pay manually",
        value: "send_invoice",
      },
    ],
    menuTax: false,
    removeScheduleDialog: false,
    menuCollectionMethod: false,
    tax: null,
    removeVoucherDialog: false,
    subscription: null,
    createVoucher: false,
    addFreeDays: false,
    daysUntilInvoiceDue: null,
    menuDaysUntilInvoiceDue: false,
    editSubscription: false,
    cancelSubscription: false,
    resumeSubscriptionDialog: false,
    invoices: [],
    taxRates: [],
    actions: [
      {
        title: "Give free days",
      },
      {
        title: "Update subscription",
      },
      {
        title: "Cancel subscription",
        color: "red-class",
      },
    ],
  }),
  computed: {
    displayTaxes() {
      return this.subscription.default_tax_rates.length
        ? this.subscription.default_tax_rates
            .map((tax) => tax.display_name)
            .join(", ")
        : null;
    },
    allowedResumeSubscription() {
      return (
        this.subscription.stripe_status === "canceled" &&
        this.$moment(this.subscription.end_date).format("YYYY-MM-DD") >
          this.$moment().format("YYYY-MM-DD")
      );
    },
    allowedChangeDaysUntilDue() {
      return this.subscription.collection_method === "send_invoice";
    },
    isSubscriptionScheduled() {
      return (
        this.subscription.schedule &&
        Object.keys(this.subscription.schedule).length > 0
      );
    },
  },
  methods: {
    ...mapActions("moderator", [
      "getModeratorSingleSubscription",
      "createDiscount",
      "deleteDiscount",
      "deleteSchedule",
      "resumeSubscription",
      "getTaxRates",
    ]),
    ...mapActions("core", ["getInvoices", "updateSubscription"]),
    statusColor(status) {
      return status === "active"
        ? "green-class"
        : status === "expired"
        ? "red-class"
        : "yellow-class";
    },
    getTaxList() {
      this.getTaxRates().then((response) => {
        this.taxRates = response;
      });
    },
    getSubscription() {
      const params = {
        stripe_id: this.$route.params.id,
      };
      this.getModeratorSingleSubscription(params).then(
        (subscription) => {
          this.subscription = subscription;
          this.tax = this.subscription.default_tax_rates.map(
            (tax) => tax.stripe_tax_rate_id
          );
        },
        () => {
          this.setSnackError("Fejl");
        }
      );
    },
    close() {
      this.menuTax = false;
      this.menuCollectionMethod = false;
      this.tax = null;
      this.menuDaysUntilInvoiceDue = false;
      this.daysUntilInvoiceDue = null;
      this.loading = false;
    },
    save() {
      this.loading = true;

      let params = {
        id: this.subscription.stripe_id,
      };

      if (this.tax) {
        params.default_tax_rates = this.tax;
      }
      if (this.collectionMethod && this.menuCollectionMethod) {
        params.collection_method = this.collectionMethod;
      }
      if (this.daysUntilInvoiceDue) {
        params.days_until_due = Number(this.daysUntilInvoiceDue);
      }
      this.updateSubscription(params).then(() => {
        this.loading = false;
        this.menuTax = false;
        this.menuCollectionMethod = false;
        this.menuDaysUntilInvoiceDue = false;
        this.$emit("success");
        this.setSnackSuccess("Gemt");
        this.getSubscription();
      }),
        () => {
          this.loading = false;
          this.setSnackError("Fejl");
        }
    },
    getInvoicesList() {
      const params = {
        subscription_key: this.$route.params.id,
      };
      this.getInvoices(params).then(
        invoices => {
          this.invoices = invoices;
        },
        () => {
          this.setSnackError("Fejl");
        }
      );
    },
    editSubscriptionButtons(item) {
      if (item.includes("Update")) {
        this.editSubscription = true;
      } else if (item.includes("free")) {
        this.addFreeDays = true;
      } else {
        this.cancelSubscription = true;
      }
    },
    undoSubscription() {
      this.loading = true;
      this.resumeSubscription({
        subscription_uuid: this.subscription.uuid,
      }).then(
        () => {
          this.getSubscription();
          this.resumeSubscriptionDialog = false;
        },
        () => {
          this.setSnackError("Fejl");
        }
      );
      this.loading = false;
    },
    deleteVoucher() {
      this.loading = true;
      this.deleteDiscount({
        id: this.subscription.stripe_discount.stripe_coupon_id,
      }).then(
        () => {
          this.loading = false;
          this.removeVoucherDialog = false;
          this.getSubscription();
        },
        () => {
          this.setSnackError("Fejl");
        }
      );
    },
    setCollectionMethod(method) {
      const data = this.collectionMethodList.find(
        (item) => item.value === method
      ).label;
      return data;
    },
    removeSchedule() {
      this.loading = true;
      this.deleteSchedule({
        stripe_id: this.$route.params.id,
      }).then(
        () => {
          this.loading = false;
          this.removeScheduleDialog = false;
          this.getSubscription();
        },
        () => {
          this.setSnackError("Fejl");
        }
      );
    },
  },
  created() {
    this.getSubscription();
    this.getInvoicesList();
    this.getTaxList();
  },
};
</script>
<style lang="scss">
.subscription-wrap {
  padding: 0 10px;
}
.subscription-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .subscription-title {
    font-size: 28px;
    color: #000000;
  }
}
.remove-wrap {
  cursor: pointer;
  font-weight: bold;
}
.v-list .v-list-item .v-list-item__title.red-class,
.red-class {
  color: red !important;
  text-transform: capitalize;
  cursor: pointer;
}
.green-class {
  color: #009688 !important;
  text-transform: capitalize;
  cursor: pointer;
}

.grey-text {
  color: #787878;
}
.subscription-content {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
  color: #000000;

  a {
    color: #5c98cf;
    text-decoration: underline;
  }
}
.bigger-text {
  font-size: 25px;
  color: #000000;
}
.yellow-class {
  color: #fed744;
  text-transform: capitalize;
  cursor: pointer;
}
.subscription-border {
  border: 0.5px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 23px 32px 23px;
}
.subscription-details-table {
  thead th {
    font-size: 16px !important;
    font-weight: 700;
    color: #000000 !important;
    border-bottom: none !important;
  }
  .row {
    justify-content: space-between;
  }
  .container {
    padding: 0;
  }
  .v-list-item {
    padding: 0;
  }
}
.action-btn {
  cursor: pointer;
}
.table-row-title {
  font-weight: 400;
  color: #787878;
  font-size: 14px;
}
.table-row-content {
  font-weight: 400;
  font-size: 14px;
  margin-top: 4px;
}
.action-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 85%;
  .v-btn__content {
    text-transform: capitalize;
  }
}
.business-name {
  font-size: 24px;
  color: #000000;
  text-transform: capitalize;
}
.card-name {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}
</style>
