<template>
  <v-select
    :items="items"
    v-model="selected"
    color="primary"
    label="Abonnement"
    item-text="nickname"
    item-value="id"
    :return-object="returnObject"
    :error-messages="errorMessages"
    :clearable="clearable"
    :outlined="outlined"

  >
    <!-- Selected item -->
    <template class="" slot="selection" slot-scope="prop">
      <v-list-item-content>
        <v-list-item-title v-html="prop.item.nickname"></v-list-item-title>
        <v-list-item-subtitle class="text--secondary"
          >{{ prop.item.currency }} {{ prop.item.amount  }} /
          {{ prop.item.interval_count }}
          {{ prop.item.interval }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </template>

    <v-list slot="no-data">
      <v-list-item>
        <v-list-item-title>Intet resultat</v-list-item-title>
      </v-list-item>
    </v-list>

    <!--List item-->
    <template slot="item" slot-scope="prop">
      <v-list-item-content class="mt-1 mb-1">
        <v-list-item-title v-html="prop.item.nickname"></v-list-item-title>
        <v-list-item-subtitle class="text--secondary"
          >{{ prop.item.currency }} {{ prop.item.amount }} /
          {{ prop.item.interval_count }}
          {{ prop.item.interval }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import { mapActions } from "vuex";

export default {
  filters: { local_numbers },
  props: {
    value: {
      required: true
    },
    errorMessages: {
      required: false,
      default: null
    },
    returnObject: {
      required: false,
      default: true
    },
    clearable: {
      required: false,
      default: false
    },
    outlined: {
      required: false,
      default: false
    }
  },
  data: () => ({
    items: []
  }),
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    ...mapActions("core", ["getStripePlans"]),
    getPlans() {
      this.getStripePlans().then(response => {
        this.items = response;
      });
    }
  },
  created() {
    this.getPlans();
  }
};
</script>
