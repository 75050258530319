<template>
  <v-dialog
    v-model="showing"
    max-width="450px"
    content-class="free-days-wrapper"
  >
   <v-card v-if="!confirmAddingDays">
      <v-card-title>
        <div>
          <div class="headline">FREE DAYS</div>
          <div class="subtitle">Free days is providing the business with a trial.
             That means It will take the current subscription end + the days you put in</div>
        </div>
      </v-card-title>
      <v-system-bar v-if="disabled" color="#FED744" :height="45" class="mt-6 mx-5">
        <div class="d-flex align-center">
          <v-icon color="white" small>fal fa-exclamation-triangle</v-icon> 
          <div  class="text-left white--text">
          Subscription is scheduled for change.
            You can’t update.
          </div>
    
        </div>
      </v-system-bar>
      <v-card-text class="dates-wrap">
        <v-text-field
            outlined
            background-color="white"
            v-model="freeDays"
            placeholder="How many free days?"
            name="How many free days?"
          ></v-text-field>
        <div class="trial-days-wrap">End date </div>
        <div class="subtitle"> {{ countEndDay }}</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel">
          cancel
        </v-btn>
        <v-btn color="primary" :loading="loading" @click="confirmAddingDays = true" :disabled="disabled">
          add free days
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-else class="confirmation-wrapper">
      <v-card-title>
        <div>
          <div class="headline">CONFIRM</div>
          <div class="subtitle">Give {{ freeDays }} free {{ freeDays > 1 ? 'days' : 'day' }} ?</div>
        </div>
      </v-card-title>

      <v-card-text class="success--text">
       {{ subscription.next_period_at | utc_to_local_datetime("MMM DD, YYYY")  }} - {{ trialDays | utc_to_local_datetime("MMM DD, YYYY")  }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel">
          cancel
        </v-btn>
        <v-btn color="primary" :loading="loading" @click="save()">
       CONFIRM
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";

import { mapActions } from "vuex";

export default {
  filters: { utc_to_local_datetime },
  props: {
    subscription: {
      required: true,
      default: () => {}, // Default object
    },
    value: {
      required: true,
    },
    disabled: {
      required: false,
      type: Boolean
    }
  },
  data: () => ({
    loading: false,
    freeDays: null,
    confirmAddingDays: false
  }),
  computed: {
    showing: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    trialDays() {
      return this.$moment(this.subscription.next_period_at).add(this.freeDays, 'days');
    },
    countEndDay() {
      return this.freeDays ? this.$moment(this.trialDays).format("MMM DD, YYYY") : this.$moment(this.subscription.next_period_at).format("MMM DD, YYYY")
    }
  },
  methods: {
    ...mapActions("core", ["updateSubscription"]),
    save() {
      this.loading = true;
      let params = {
        id: this.subscription.stripe_id,
        trial_days: Number(this.freeDays),
      };
      this.updateSubscription(params).then(() => {
        this.loading = false;
        this.showing = false;
        this.$emit("success");
        this.setSnackSuccess("Gemt");
      }),
      () => {
        this.loading = false;
        this.setSnackError("Fejl");
      };
    },
    cancel() {
      this.$emit('cancel', true);
      this.confirmAddingDays = false;
      this.freeDays = null;
    }
  }
};
</script>
<style lang="scss">
.free-days-wrapper {
  font-size: 14px;
  color: var(--v-primary-base);
  .headline {
    font-size: 16px !important;
    font-weight: 500;
  }
  .v-card{
    padding: 0 18px 18px 14px;
  }
  .trial-days-wrap {
    font-size: 14px;
    font-weight: 300;
    color:var(--v-greytxt-base) ;
  }
  
}
.dates-wrap{
  width: 60%;
  padding-top: 16px!important;
}
.subtitle {
    font-size: 14px !important;
    font-weight: 400;
    line-height: 16px;
    color: var(--v-primary-base);
  }

</style>
