<template>
  <v-dialog
    v-model="showing"
    max-width="650px"
    content-class="update-subscription-wrapper"
  >
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Choose the subscription</div>
          <div class="subtitle-1 grey--text"></div>
        </div>
      </v-card-title>
      <v-card-text>
        <stripe-product-plan-select v-model="selectedPlan" clearable outlined />

        <v-radio-group
          v-model="scheduleChange"
          column
          label="A plan upgrade takes effect immediately"
        >
          <v-radio
            label="From next period"
            color="primary"
            :value="true"
          ></v-radio>
          <v-radio
            label="Yes, immediately"
            color="primary"
            :value="false"
          ></v-radio>
        </v-radio-group>
        <v-radio-group
          v-model="prorate"
          column
          label="Credit remaining amount of current subscription"
        >
          <v-radio
            label="YES credit remaining amount from next invoice"
            color="primary"
            value="create_prorations"
          ></v-radio>
          <v-radio
            label="No, charge 100% of the new subscription"
            color="primary"
            value="none"
          ></v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('cancel', true)">
          cancel
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!validNewSubscription"
          :loading="loading"
          @click="save()"
        >
          confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import friendly_numbers from "@/helpers/filters/friendly_numbers";
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import StripeProductPlanSelect from "../selects/StripeProductPlanSelect";

import { mapActions } from "vuex";

export default {
  components: { StripeProductPlanSelect },
  filters: { friendly_numbers, local_numbers, utc_to_local_datetime },
  props: {
    subscription: {
      required: true,
      default: () => {}, // Default object
    },
    value: {
      required: true,
    },
  },
  data: () => ({
    loading: false,
    selectedPlan: null,
    scheduleChange: false,
    percentOff: null,
    couponDuration: "forever",
    collectionMethod: "charge_automatically",
    prorate: "create_prorations"
  }),
  computed: {
    validNewSubscription() {
      if (
        this.selectedPlan &&
        this.selectedPlan.id === this.subscription.stripe_plan 
      ) {
        return false;
      }

      return true;
    },
    showing: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions("core", ["updateSubscription"]),
    save() {
      this.loading = true;

      let params = {
        id: this.subscription.stripe_id,
        schedule: this.scheduleChange
      };

      if (this.selectedPlan) {
        params.plan = this.selectedPlan.id;
        params.schedule = this.scheduleChange;
        params.proration_behavior = this.prorate;
      }
      this.updateSubscription(params).then(
        () => {
          this.loading = false;
          this.$emit("success");
          this.setSnackSuccess("Gemt");
        },
        () => {
          this.loading = false;
          this.setSnackError("Fejl");
        }
      );
    }
  }
};
</script>
<style lang="scss">
.update-subscription-wrapper {
  div,
  .v-label{
    font-size: 14px;
    font-weight: 400;
    color: #000000;
  }
  .v-card__text {
    max-width: 80%!important;
  }
  .v-input--radio-group--column .v-input--radio-group__input>.v-label{
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
  }
}
</style>
